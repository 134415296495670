import React from 'react';
import styled, { css } from 'styled-components';

import { OutlinedButton } from 'components/Button';
import Layout from 'layouts';
import { SEO } from 'SEO';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 10vmin 5vmin;
`;

const Code = styled.div(
  ({ theme }) => css`
    color: ${theme.color.blue[400]};
    font-size: 5rem;
    font-weight: 600;
  `
);

const Description = styled.h1(
  ({ theme }) => css`
    color: ${theme.color.gray[200]};
    font-size: 1.1rem;
    margin-bottom: ${theme.spacing.big};
  `
);

const NotFoundPage: React.FC = () => (
  <Layout>
    <SEO title="404 - Nie znaleziono" canonicalHref="/404" />
    <Container>
      <Code>404</Code>
      <Description>Szukana strona nie została odnaleziona.</Description>
      <OutlinedButton href="/">Wróć</OutlinedButton>
    </Container>
  </Layout>
);

export default NotFoundPage;
